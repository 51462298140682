.backToHome {
    position: absolute;
    right: 0.12rem;
    width: 0.32rem;
    height: 0.32rem;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.2rem;
    border-radius: 50%;
    color: #fff;
    margin-left: 0.12rem;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
}
.shareBox {
    display: flex;
    padding: 0.3rem 0.12rem;
}
.shareItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 0.9rem;
    font-size: 0.12rem;
}
.shareImg {
    width: 0.4rem;
    height: 0.4rem;
}