.shopp-item {
	width: 100%;
	background-color: #fff;
	box-sizing: border-box;
	border-radius: 0.12rem;
	overflow: hidden;
	font-size: 0;
	position: relative;
	display: flex;
	flex-direction: column;
	.collect-status {
		font-size: 0.2rem;
	}

	.mask-collect {
		width: 100%;
		height: 2.3rem;
		background: rgba(0, 0, 0, 0.6);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;
		display: flex;
		justify-content: center;
		align-items: center;

		.yet-join-collect {
			height: 0.62rem;

			display: flex;
			flex-direction: column;
			align-items: center;

			line-height: 22px;
			font-size: 0.32rem;

			.root-svg {
				font-size: 0.32rem;
			}

			.tips {
				margin-top: 0.08rem;
				font-size: 0.14rem;
				font-family:
					PingFang SC-Regular,
					PingFang SC;
				font-weight: 400;
				color: #ffffff;
			}
		}
	}

	.shopp-img {
		width: 1.715rem;
		height: 1.715rem;
		border-radius: 0.12rem 0.12rem 0 0;
		overflow: hidden;
		position: relative;
	}

	.tag-mall {
		z-index: 1;
		position: absolute;
		top: 0.04rem;
		right: 0.04rem;
		border-radius: 0.14rem;
		border: 1px solid #ffb59a;
		background: rgba(255, 80, 16, 0.1);
		padding: 0rem 0.08rem;
		color: #ff5010;
		font-size: 0.12rem;
		font-weight: 500;
		line-height: 0.24rem;
	}

	.tag-limit {
		padding: 0rem 0.08rem;
		border-radius: 0.14rem;
		border: 1px solid #ff7c4a;
		background: rgba(255, 80, 16, 0.5);
		position: absolute;
		top: 0.04rem;
		right: 0.14rem;
		color: #fff;
		font-size: 0.12rem;
		font-weight: 500;
		line-height: 0.24rem;
		display: flex;
		align-items: center;
		svg {
			margin-right: 0.04rem;
			font-size: 0.14rem;
		}
	}

	.mask-sold-out {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(255, 255, 255, 0.4);
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		.btn-style {
			border-radius: 0.16rem;
			border: 1px solid #fff;
			background: rgba(0, 0, 0, 0.3);
			padding: 0.04rem 0.08rem;
			color: #fff;
			font-size: 0.1rem;
			font-weight: 500;
			line-height: 0.24rem;
		}

		.sold-out {
			border-radius: 0.16rem;
			border: 1px solid #fff;
			background: rgba(0, 0, 0, 0.3);
			padding: 0.04rem 0.24rem;
			color: #fff;
			font-size: 0.12rem;
			font-weight: 500;
			line-height: 0.24rem;
		}
	}

	.shopp-title-view {
		margin: 0.08rem 0.08rem 0.04rem 0.08rem;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 86%;
		white-space: nowrap;
		// display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		font-size: 0.12rem;
		line-height: 0.2rem;
		// height: 0.4rem;
		color: var(--color-text-primary, #1c2026);
	}

	.shopp-title-view-disabled {
		opacity: 0.4;
	}

	.tag-line {
		width: 100%;
		height: 0.16rem;
		margin-top: 0.04rem;

		.tag {
			padding: 0 0.04rem;
			height: 0.16rem;
			display: inline-block;
			background: rgba(240, 147, 54, 0.08);

			font-size: 0.1rem;
			font-family:
				PingFang SC-Regular,
				PingFang SC;
			font-weight: 400;
			color: #f09336;
			line-height: 0.16rem;
		}
	}

	.price-show {
		margin: 0.04rem 0.08rem 0;
		display: flex;
		justify-content: space-between;
		flex: 1;
		color: var(--color-text-primary, #1c2026);
		text-align: center;
		font-size: 0.12rem;
		font-weight: 600;
		line-height: 0.16rem;
		.price-show-tips {
			color: var(--color-text-secondary, #7e8694);
			margin-left: 0.04rem;
		}

		.collect {
			font-size: 0.2rem;
			color: #ff5010;

			.no-collect {
				color: #cdd2da;
			}
		}
	}

	.no-login-tips {
		color: #ff5010;
		text-align: center;
		font-size: 0.1rem;
		font-weight: 500;
		line-height: 0.16rem;
		margin-top: 0.04rem;
	}
	.purchased-goods-style {
		border-radius: 0.12rem;
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.4);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.star-container {
			width: 0.4rem;
			height: 0.4rem;
			border-radius: 50%;
			background-color: #ffa400;
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 1;
		}
		.star-img {
			width: 0.2rem;
			height: 0.2rem;
		}
		.star-container-default {
			background-color: rgba(255, 255, 255, 0.2);
			width: 0.4rem;
			height: 0.4rem;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 1;
		}
	}
}
