body {
	margin: 0;
	font-family:
		// 'NotoSansJP',
		'Hiragino Kaku Gothic ProN',
		'Hiragino Sans',
		Meiryo,
		sans-serif,
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		'Roboto',
		'Oxygen',
		'Ubuntu',
		'Cantarell',
		'Fira Sans',
		'Droid Sans',
		'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// font-weight: 500;
}

// div, span, p {
// 	font-family:
// 		'NotoSansJP',
// }

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
.adm-checkbox-content {
	font-size: 0.14rem;
}
.adm-button {
	font-size: 0.14rem;
	border: 1px solid rgba(0, 0, 0, 0.07);
	color: #333;
}
.adm-button-primary {
	border-radius: 0.24rem;
	// padding: 0.12rem 0.16rem;
	// background: linear-gradient(90deg, #FF7E3E 0%, #FF5010 50%, #FE4B0F 100%);
	border: 2px solid var(--color-border-border, #cdd2da);
	color: var(--color-text-primary, #1c2026) !important;
	text-align: center;
	font-size: 0.14rem;
	font-weight: 500;
	line-height: 0.22rem;
	background: #fff;
}
.adm-stepper .adm-stepper-minus,
.adm-stepper .adm-stepper-plus {
	background: #fff;
	border: 1px solid #dcdcdc;
}
.adm-stepper svg {
	color: #1c2026;
}
.adm-checkbox .adm-checkbox-icon {
	width: 0.16rem;
	height: 0.16rem;
}
.adm-stepper-middle {
	border-left: none;
	border-right: none;
	.adm-stepper-input {
		background: #fff;
		border-top: none;
		border-bottom: none;
		box-sizing: border-box;
	}
}

body,
.root,
#J_B2B,
.layout-style {
	position: fixed;
	left: 0;
	top: 0;
	// -webkit-text-size-adjust: 100%;
	// background: #fff;
	// min-width: 320px;
	// margin: 0;
	// padding: 0;
	// height: 100%;
	// width: 100%;
	// overflow-x: hidden;
	// -webkit-overflow-scrolling: touch;
	height: 100vh;
	width: 100vw;
}
#J_B2B {
	background: #f0f4f7;
	&::before {
		width: 100vw;
		height: 100vh; /*设置为全屏背景模式*/
		position: absolute; /*图片定位*/
		top: 0;
		left: 0;
		content: '';
		z-index: -1;
		background: url(https://static-s.theckb.com/BusinessMarket/Easy/H5/bg_1.png),
			url(https://static-s.theckb.com/BusinessMarket/Easy/H5/bg_2.png),
			url(https://static-s.theckb.com/BusinessMarket/Easy/H5/bg_3.png);
		background-repeat: no-repeat;
		background-size:
			0.82rem 1.52rem,
			1.09rem 1.89rem,
			1.38rem 1.41rem;
		background-position:
			top right,
			0 2.25rem,
			bottom left;
		filter: blur(27px);
	}
}

.layout-style {
	flex-direction: column;
	justify-content: space-between;
	overflow-y: scroll;

	// background-size:
	// 	0.82rem 1.52rem,
	// 	1.09rem 1.89rem,
	// 	1.38rem 1.41rem;
	// background-position:
	// 	top right,
	// 	2.55rem left,
	// 	bottom left;
	// background-repeat: no-repeat;
}
.layout-content {
	// margin-bottom: 0.61rem;
	// margin-top: 0.6rem;
	overscroll-behavior-y: contain;
	-webkit-overflow-scrolling: touch;
}
.not-need-button-width-modal {
	.adm-button {
		width: auto !important;
		padding: 0.12rem 0.08rem 0.12rem 0.08rem !important;
	}
}

.adm-button-primary {
	border: none;
	background: var(--color-text-primary, #1c2026);
	color: #fff !important;
}

#cate-view-hidden-scroll::-webkit-scrollbar {
	display: none;
}

.x-space {
	margin-right: 0.08rem;
}

.adm-list-default .adm-list-body {
	border: none;
	border-bottom: none;
	border-top: none;
}
