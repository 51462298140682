.open-app {
	position: fixed;
	top: .65rem;
	right: 0.17rem;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	align-items: center;

	.open-app-header {
		width: 0.6rem;
		height: 0.6rem;
		border-radius: 50%;
		background-color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 0.38rem;
			position: relative;
			top: 0.04rem;
		}
	}

	.open-app-text {
		padding-left: 0.11rem;
		padding-right: 0.11rem;
		height: 0.25rem;
		margin-top: -0.12rem;
		text-align: center;
		background-color: #000;
		border-radius: 30px;
		color: #FFF;
		z-index: 1;
		font-family: "PingFang SC";
		font-size: 0.12rem;
		font-style: normal;
		font-weight: 500;
		line-height: 0.25rem;
	}
}