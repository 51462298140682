.modal-goto-purchase-mask{
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-goto-purchase{
        // height: auto;
        box-sizing: border-box;
        min-height: 5.81rem;
        background-color: #fff;
        border-radius: 0.08rem;
        overflow: hidden;
        text-align: center;
        padding: 0.2rem 0;
        width: 3.5rem;
    }
    // .title{
    //     margin-bottom: 0.09rem;
    //     margin-top: 0;
    // }
    // .desc{
    //     margin-bottom: 0.12rem;
    // }
    // .title, .desc{
    //     font-size: 0.14rem;
    //     font-weight: bold;
    //     color: #2E4968;
    //     line-height: 0.2rem;
    // }
    .imgs{
        width: 3.25rem;
    }
    .default-select{
        font-weight: 400;
        color: #838383;
        line-height: 0.22rem;
        margin-top: 0.16rem;
    }
    .btns{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.05rem;
        margin-top: 0.16rem;
        .adm-button{
            min-width: 1.5rem;
            padding: 0.09rem 0.09rem;
        }
    }
    .purchase-scroll{
        max-height: 4.85rem;
        overflow-y: auto;
    }
    .adm-checkbox-content{
        font-size: 0.12rem;
        text-align: left;
    }
    .adm-checkbox-icon{
        width: 0.14rem;
        height: 0.14rem;
    }
    .position-relative{
        position: relative;
    }
    .qr-code{
        position: absolute;
        bottom: 0.26rem;
        right: 0.18rem;
        width: 1rem;
        height: auto;
    }
    .img-title{
        width: 2.77rem;
        margin-bottom: 0.08rem;
    }
    .easy-text{
        width: 3.27rem;
        margin-top: 0.5rem;
        border-radius: 0.12rem;
    }
    .easy-to-purchase-icon{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 2.35rem;
    }
    .vip-text{
        width: 3.27rem;
        border-radius: 0.12rem;
    }
    .purchase-modal-tip{
        font-size: 0.14rem;
        font-weight: bold;
        color: #2E4968;
        line-height: 0.2rem;
    }
}
