.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/** 表格覆盖 */

.ant-tabs-ink-bar {
  transform: scale(0.5);
  height: 4px !important;
}

.ant-tabs-nav {
  background-color: #fff;
  margin: 0 !important;
  padding-left: 20px;
}

.ant-select-suffix {
  color: #969799;
}

.ant-select-item-option-selected {
  color: #008060 !important;
}

.table-icon-arrow {
  font-size: 26px;
}

.nodata-img-cover {
  animation: nodata-img 1s;
  padding: 60px 0;
}

.nodata-img {
  margin: 16px;
  width: 100px;
}

@keyframes nodata-img {
  0% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.x-left {
  float: left;
}

.x-right {
  float: right;
}


/** 重置下拉icon */
.ant-select-arrow span {
  display: none !important;
}

.ant-select-arrow::after {
  content: "\e67e";
  font-family: "iconfont";
  font-size: 30px;
  margin-right: -5px;
  color: #232323;
}

/** 子表格样式 */
.ant-table-expanded-row-fixed {
  padding: 0 !important;
}


.ant-table-cell {
  border-radius: 0 !important;
}

.ant-table {
  margin-inline: 0 !important;
}

.table-isOpenRow,
.ant-table-cell-row-hover,
.table-isOpenRow .ant-table-cell {
  background-color: #EDF5EF !important;
}

table,
.ant-table-header {
  border-radius: 2px 2px 0 0 !important
}

.ant-table .ant-table-cell::before {
  background: #d9d9d9 !important;
}


:where(.css-dev-only-do-not-override-1thi6p4).ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: #d9d9d9 !important;
}

:where(.css-dev-only-do-not-override-1thi6p4).ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
  border-inline-end: none;
}

:where(.css-dev-only-do-not-override-em5cq7).ant-table-wrapper .ant-table-tbody>tr>td {
  border-bottom-color: #f8f8f8 !important;
}