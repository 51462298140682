.move-view {
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: .24rem;
        height: .24rem;
    }
}