:root {
	--color-primary: #ffffff;
	--adm-color-primary: #1c2026;
	--color-text-primary: #1c2026;
	--color-text-secondary: #7e8694;
	--color-primary-background: linear-gradient(
		90deg,
		#ff7e3e 0%,
		#ff5010 50%,
		#fe4b0f 100%
	);
}
