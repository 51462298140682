.goods-detail-swiper {
    width: 3.75rem;
    height: 3.75rem;
    position: relative;

    .swiper-image {
        width: 3.75rem;
        height: 3.75rem;
        position: relative;

        .wrap {
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.4) 100%);
            z-index: 1;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }

        .img {
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }

    .indicator {
        height: 0.4rem;
        position: absolute;
        right: 0.2rem;
        bottom: 0.1rem;

        span {
            font-size: 0.16rem;
            font-family:
                PingFangSC-Medium,
                PingFang SC;
            color: #fff;
            line-height: 0.16rem;

            &:nth-child(2) {
                font-size: 0.1rem;
                font-family: PingFang SC;
                color: #fff;
            }
        }
    }

    .img-list {
        position: absolute;
        bottom: 0.28rem;
        display: flex;
        align-items: center;
        left: 50%;
        transform: translateX(-50%);

        // margin-left: 50%;
        .imgBox {
            width: 0.4rem;
            height: 0.4rem;
            overflow: hidden;
            border-radius: 0.08rem;
            margin-right: 0.12rem;

            &:last-child {
                margin-right: 0;
            }

            &.active {
                border: 2px solid #b0b7c2;
            }

            .img {
                width: 0.4rem;
                height: 0.4rem;
            }
        }
    }
}