.shop-cart-empty {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
    // justify-content: center;
    height: 100%;
}

.empty-desc {
    font-size: .12rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
    line-height: .17rem;
}

.space-style {
    // padding: 0 0.1rem;
    display: flex;
    justify-content: center;
}

.empty-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.empty-no-purchase {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.empty-content-height {
    height: 80%;
}