.no-scroll {
	position: relative;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
}

.header-right-cart {
	height: 0.44rem;
	display: flex;
	align-items: center;

	.cart-num-area {
		position: relative;
		display: flex;
		padding: 0.08rem;
		background-color: #fff;
		border-radius: 50%;

		.cart-num {
			padding: 0 0.04rem;
			height: 0.13rem;
			position: absolute;
			background: #eb4545;
			border-radius: 0.06rem;
			border: 1px solid #ffffff;
			position: absolute;
			top: 0.04rem;
			right: 0.04rem;
			transform: translate(50%, -50%);

			font-size: 0.09rem;
			font-family:
				PingFangSC-Medium,
				PingFang SC;
			font-weight: 500;
			color: #ffffff;
		}
	}
	.cart-text {
		font-size: 0.11rem;
		font-family:
			PingFangSC-Medium,
			PingFang SC;
		font-weight: 500;
		color: #232323;
		margin-left: 0.04rem;
	}
}

.goods {
	height: 100%;
	// width: 100%;
	// height: 100%;
	// position: relative;

	.header {
		display: flex;
		justify-content: center;
		flex-direction: column;

		position: fixed;
		left: 0;
		right: 0;
		top: 0.35rem;
		z-index: 1000;
		padding-top: 0.18rem;
	}

	.border-radio {
		border-radius: 0px 0px 8px 8px;
		overflow: hidden;
	}

	.shopp-list {
		width: 100%;
		height: 100%;
		// position: absolute;
		// left: 0;
		// top: 0;
		// right: 0;
		// bottom: 0;

		.cate-shopp-list {
			.shopp-view {
				padding: 0 0.12rem;
				box-sizing: border-box;

				.shopp-item {
					width: 1.67rem;
					// background: rgba(0,0,0,0.02);
					padding: 0.08rem;
					box-sizing: border-box;
					border-radius: 0.12rem;
					overflow: hidden;

					.shopp-img {
						width: 1.51rem;
						height: 1.4rem;
						border-radius: 8px;
						overflow: hidden;
					}

					.shopp-title {
						font-size: 0.14rem;
						line-height: 0.22rem;
						color: #1c2026;
						font-family:
							PingFang SC-Regular,
							PingFang SC;

						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
					}

					.tag {
						height: 0.2rem;
						padding: 0 0.04rem;
						box-sizing: border-box;
						font-size: 0.12rem;
						color: #f09336;
						background-color: #fef6ef;
						line-height: 0.2rem;
						display: inline-block;
						font-family:
							PingFang SC-Regular,
							PingFang SC;
						border-radius: 0.04rem;
						overflow: hidden;
						margin-top: 0.08rem;
					}

					.no-login-tips {
						font-size: 0.14rem;
						font-family:
							PingFang SC-Regular,
							PingFang SC;
						font-weight: 400;
						color: #f04c34;
						line-height: 0.22rem;
						text-align: center;
					}
				}
			}
		}
	}
	.only-watch-purchase {
		padding-top: 0.16rem;
		border-top: 1px solid #f8f8f8;
	}
	:global(.adm-checkbox-content) {
		font-size: 0.12rem;
	}
	:global(.adm-checkbox) {
		display: flex;
		align-items: center;
	}
	:global(.adm-checkbox-custom-icon) {
		width: 0.14rem;
		height: 0.14rem;
	}
}
.classify-title {
	max-width: 1.43rem;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.classify-reset {
	// height: 0.4rem;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
	background-color: #fff;
	width: 94%;
	position: absolute;
	bottom: 0;
	padding-bottom: 0.12rem;
	padding-top: 0.12rem;
	border-radius: 0.12rem;
}
.filter {
	width: 100%;
	// height: 1.96rem; /* todo不能写死*/
	// height: .56rem; /* todo不能写死*/
	box-sizing: border-box;

	.content {
		width: 100%;
		height: 0.46rem;
		padding: 0.08rem 0.53rem 0 0.12rem;
		box-sizing: border-box;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		font-size: 0.14rem;
		font-family:
			PingFangSC-Medium,
			PingFang SC;
		font-weight: 500;
		color: #656566;
		line-height: 0.18rem;

		.active-color {
			color: #ff5010;
		}

		.left {
			display: flex;
			align-items: center;
			padding: 0.07rem 0.12rem;
			background-color: #fff;
			border-radius: 0.16rem;
			margin-right: 0.12rem;
			.img {
				margin-left: 0.05rem;
			}
			.active-image {
				margin-left: 0.05rem;
			}
		}

		.filter-area {
			display: flex;
			align-items: center;
			border-radius: 0.16rem;
			background: #fff;
			position: absolute;
			right: 0.12rem;
			.filter-title {
				padding-left: 0.12rem;
				color: #ff5010;
			}
			.active-img {
				color: #ff5010;
				padding: 0.08rem;
			}
			.img {
				color: #1c2026;
				padding: 0.08rem;
			}
		}
	}

	.filter-list {
		width: 94%;
		max-height: 60vh;
		margin-left: 3%;
		padding: 0 0.12rem;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 0.12rem;
		padding-bottom: 0.5rem;
		overflow: auto;
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
		.list-row {
			width: 100%;
			height: 0.46rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			border-bottom: 1px solid #f8f8f8;
			box-sizing: border-box;

			font-size: 0.14rem;
			font-family:
				PingFangSC-Regular,
				PingFang SC;
			font-weight: 400;
			color: #333333;
			line-height: 0.14rem;
		}
	}

	.right-filter-view {
		width: 94%;
		margin-left: 3%;
		padding: 0.12rem 0.12rem 0.8rem 0.12rem;
		box-sizing: border-box;
		position: relative;
		background-color: #fff;
		border-radius: 0.12rem;

		.title {
			font-size: 0.14rem;
			font-family:
				PingFangSC-Medium,
				PingFang SC;
			font-weight: 500;
			color: #333333;
			line-height: 0.2rem;
		}

		.line {
			width: 0.09rem;
			height: 1px;
			background-color: #999999;
		}

		.price-range {
			width: 100%;
			height: 0.32rem;
			margin-top: 0.08rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.searchContent {
				width: 1.475rem;
				height: 100%;
				background: #f0f2f5;
				border-radius: 0.16rem;
			}
		}

		.price-choose-area {
			display: flex;
			flex-wrap: wrap;
			padding-bottom: 0.16rem;
			.active-choose-item {
				min-width: 0.2rem;
				height: 0.32rem;
				padding: 0 0.12rem;
				border-radius: 0.16rem;
				margin-top: 0.12rem;
				margin-right: 0.12rem;
				background: #f0f2f5;

				font-size: 0.12rem;
				font-family:
					PingFangSC-Regular,
					PingFang SC;
				font-weight: 400;
				color: #333333;
				line-height: 0.32rem;

				text-align: center;
			}
			.active-selected-item {
				min-width: 0.2rem;
				height: 0.32rem;
				padding: 0 0.12rem;
				border-radius: 0.16rem;
				margin-top: 0.12rem;
				margin-right: 0.12rem;
				background: rgba(255, 80, 16, 0.12);

				font-size: 0.12rem;
				font-family:
					PingFangSC-Regular,
					PingFang SC;
				font-weight: 400;
				color: #ff5010;
				line-height: 0.32rem;

				text-align: center;
			}
			.price-choose-item {
				width: 1rem;
				height: 0.32rem;
				border-radius: 0.16rem;
				margin-top: 0.12rem;
				margin-right: 0.12rem;
				background: #f0f2f5;

				font-size: 0.12rem;
				font-family:
					PingFangSC-Regular,
					PingFang SC;
				font-weight: 400;
				color: #333333;
				line-height: 0.32rem;

				text-align: center;

				&:nth-child(3) {
					margin-right: 0;
				}
			}
		}

		.btns {
			width: 99%;
			border-radius: 0 0 0.12rem 0.12rem;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 0.67rem;
			background-color: #fff;
			display: flex;
			justify-content: flex-end;
			padding: 0.12rem;
			box-sizing: border-box;

			.btn {
				width: 1.475rem;
				height: 0.4rem;
				padding: 0 0.36rem;
				font-size: 0.14rem;
				font-family:
					PingFangSC-Medium,
					PingFang SC;
				font-weight: 500;
				color: #333333;
				line-height: 0.4rem;
				border: 2px solid var(--color-border-border, #cdd2da);
				border-radius: 0.22rem;
				display: flex;
				justify-content: center;
				align-self: flex-end;

				&:nth-child(2) {
					border: 2px solid var(--color-border-border, #1c2026);
					margin-left: 0.1rem;
					background: #1c2026;
					color: #fff;
				}
			}
		}
	}
}
.mask {
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 1.48rem;
	left: 0;
	right: 0;
	bottom: 0;
	// background: rgba(0, 0, 0, 0.5);
	z-index: 100;
}
.header {
	padding-bottom: 0.06rem;
	position: relative;
	z-index: 101;
}
