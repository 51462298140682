.header-coupon {
	background: url(https://static-jp.theckb.com/static-asset/easy-h5/coupon_goods_bg.png);
	background-size: 100% 100%;
	width: 94vw;
	min-height: 0.6rem;
	padding: 0.08rem 0.24rem 0 0.24rem;
	font-size: 0.12rem;
	font-weight: 600;
	box-sizing: border-box;
	margin-left: 3vw;
	display: flex;
	flex-flow: column;
	text-align: center;
}